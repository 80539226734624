<template>
  <div style="width:700px; background-color:#000;">
    <button @click="shutDownWindown()">关闭</button>
    <el-table
            :data="tableData"
            style="width: 100%">
      <el-table-column
              prop="date"
              label="crt创建时间"
              width="180">
      </el-table-column>
      <el-table-column
              prop="name"
              label="crtId"
              width="180">
      </el-table-column>
      <el-table-column
              prop="address"
              label="crt名称">
      </el-table-column>
    </el-table>
  </div>

</template>

<script>
  export default {
    data () {
      return {


      };




    },
    props: {
      tableData: null
    },

    components: {},

    computed: {},

    mounted() {

    },

    methods: {
      shutDownWindown(){
        console.log(this.tableData)
        this.$parent.shutdownSubmitwindows();
      }
    }
  }

</script>
<style lang='scss' scoped>
</style>