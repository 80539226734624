<!--  -->
<template>
  <div class="container-fluid panel-custom" style="background:#fff ;width:1000px;height:400px;overflow-x:auto">

    <ShowCrtInfo :tableData="tableData" ref="ShowCrtInfo" :class="{submitWindowsViewClass:true, displayBlock:this.submitWindows,displayNone:!this.submitWindows,}"></ShowCrtInfo>




    <div class="row col-md-12 col-sm-12 col-xs-12">
      <label class="col-sm-10 control-label"><span
                style="color: red">*</span>绑定公司：</label>
      <div class="row col-sm-12  form-group">
        <div class="col-sm-10">
                <el-cascader placeholder="请选择需要绑定的公司（默认 无）" :show-all-levels="false" clearable ref="cascader" v-model="checkedCom" :options="allCompanyList" @change="checkedCompany" :props="defaultProps" style="width:100%">
                </el-cascader>

        </div>
      </div>


      <div class="row col-sm-12  form-group">
        <label class="col-sm-10 control-label"><span
                style="color: red">*</span>建筑物类型：</label>
        <label class="col-sm-12 control-label" id="buildType"></label>
        <div class="col-sm-10">
          <input class="form-control" name="systemCode" id="systemCode" required value="" />
        </div>
      </div>

      <div class="row col-sm-6  form-group">
        <label class="col-sm-10 control-label"><span
                style="color: red">*</span>建筑物高度(单位:米 一层楼按照三米计算)：</label>
        <div class="col-sm-10">
          <input class="form-control" onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')" name="systemName" id="systemName" required
                 value="" />
        </div>
      </div>

      <div class="row col-sm-6  form-group">
        <label class="col-sm-10 control-label"><span
                style="color: red">*</span>建筑物颜色 例如：rgba(220,220,220, 1)</label>
        <div class="col-sm-10">
          <input class="form-control" name="systemColor" id="systemColor" required
                 value="" />
        </div>
      </div>


      <div class="row col-sm-10  form-group">
        <label class="col-sm-12 control-label"><span
                style="color: red"></span>添加建筑物属性(例：address:天津)：</label>
      </div>
      <div class="row col-sm-12  form-group">
        <label class="col-sm-6 control-label"><span
                style="color: red"></span>建筑物如需绑定设备以及设备属性参照以下示例 </label>
        <button type="button" class="" @click="startSubmitwindows()">
          查看所有crtId
        </button>


      </div>
      <div class="row col-sm-10   form-group">
        <label class="col-sm-12 control-label"><span
                style="color: red"></span>ObjectInfo:id@1234&type@smoke......</label>
      </div>

      <div class="row col-sm-4  form-group">
        <div id="phoneDiv">
          <div class="inputText1 phone ming">
            <span class="iconfont" @click="addPhone('')"><button>添加</button></span>
          </div>
        </div>
      </div>

    </div>

    <div class="row col-md-12 col-sm-12 col-xs-12"
         style="text-align: center;">
      <button type="button" class="btn btn-primary btn-sm confirm" @click="save()">
        <span class="glyphicon glyphicon-ok" ></span>保存
      </button>
      <button type="button" class="btn btn-primary btn-sm cancel" @click="closeItself()">
        <span class="glyphicon glyphicon-remove" ></span>取消
      </button>


    </div>
  </div>
</template>

<script>
  import jQuery from 'jquery'
  import $ from 'jquery'
  import ShowCrtInfo from "@/views/mapBuiding/ShowCrtInfo";
  import dataStorage from '@/../utils/dataStorage.js'
  import companySystem from "@/api/managementApi/CompanyInfo";

  export default {
    data () {
      return {
        allCompanyList: [], // 绑定公司
        checkedCom: '', // 绑定公司选择
        checkedValue1: [], // 选择的公司
        defaultProps: {
                children: "smallCompanyList", // 子公司节点
                label: "name", // 公司名
                value: "id",
                checkStrictly: true,
            },
            companyNameById: {},
        baseURL:"",
        //属性键值对匹配
        sxInt : 0,
        jsonStr :"",
        this_ciId : "0",
        systemCode : "",
        systemName : "",
        systemColor : "",
        submitWindows:false,
        tableData:[],
      };
    },

    props: {
      dataForSon: {
        baseUrl:"",
        message:"",
        ciId:"",
      }
    },

    components: {
      ShowCrtInfo
    },

    computed: {},
    created() {
        this.getAllCompanyList()
        this.getCompanyInfo()
    },

    mounted() {

      let that =this;
      window.delPhone=that.delPhone;
      jQuery.ajax({
        url :  this.dataForSon.baseUrl +"Zz3dmapBuildingController/getBuildingTypeInfo",
        type : "POST",
        async : true,
        dataType : "json",
        data : $.param({
        }),
        success : function(result) {
          var buildTypeHtml = ""
          for(let i in result){
            buildTypeHtml =buildTypeHtml + result[i].name + ":"+result[i].code+";"
          }
          document.getElementById("buildType").innerHTML = buildTypeHtml;
        },

      });
    },

    methods: {
              getCompanyInfo() {
            companySystem.getCompanyInfo().then((res) => {
                for (let i in res.data.data.list) {
                    this.companyNameById[res.data.data.list[i]["id"]] = res.data.data.list[i]["name"]
                }
            });
        },

        // 查询公司结构
        getAllCompanyList() {
            companySystem.getAllCompanyStructure().then((res) => {
                // 公司结构集合
                this.allCompanyList = res.data.data.list;
                console.log(res.data.data.list);
                // this.companyNameById
                let lsCompany = {}

            });
        },
      delPhone (i) {
        console.log(i)
        console.log(i.parentNode)
        i.parentNode.remove();
      },
      //添加删除自定义属性标签
      addPhone (num) {
        var that = this
        var div = $('<div></div>'); //创建一个父div
        $(div).attr('id', 'parent');
        $(div).addClass('inputText1 phone ming');
        var p = $('<p></p>');
        var input = $('<input type="text" style="width:500px" name="phoneValue" id="'+(that.sxInt++) +'" value="'+num+'" />');
        var span = $('<span  onclick="delPhone(this)"><button >删除</button></span>');
        p.appendTo(div);
        input.appendTo(div);
        span.appendTo(div);
        $(div).appendTo('#phoneDiv');
      },
      //删除自定义属性标签

        // 选择的公司
        checkedCompany() {

            this.checkedValue1 = this.$refs.cascader.getCheckedNodes()[0].data.id;
            // 选择后让选择框消失
            this.$refs.cascader.dropDownVisible = false;
            // this.deviceInfo.name.push("绑定公司")
            console.log(this.checkedValue1);
        },
      save() {
        var that= this

        if ($("#systemCode").val() == null || $("#systemCode").val() == "") {
          alert("请填写建筑物类型")
          return null;
        }
        if ($("#systemName").val() == null || $("#systemName").val() == "") {
          alert("请填写建筑物高度")
          return null;
        }
        if ($("#systemColor").val() == null || $("#systemColor").val() == "") {
          alert("请填写建筑物颜色")
          return null;
        }

         if (this.checkedValue1 == null || this.checkedValue1 == "") {
          alert("请填选择公司")
          return null;
        }

        var customProperties = ""
        var Phones = document.getElementsByName("phoneValue");

        for (var i = 0; i < Phones.length; i++){

          if(Phones[i].value != null && Phones[i].value.length >= 3 ){

            var reg = RegExp(/:/);
            if(Phones[i].value.match(reg)){
              console.log(Phones[i].value)
              customProperties = customProperties + Phones[i].value+"&&"
            }
          }


        }

        if(customProperties.match(RegExp(/&/)) && customProperties.length>2){
          customProperties =customProperties.substring(0,customProperties.length-2)
        }


        //编辑


        var systemCode = $("#systemCode").val();
        var systemName = $("#systemName").val();
        var systemColor = $("#systemColor").val();



        jQuery.ajax({
          url : this.dataForSon.baseUrl + "Zz3dmapBuildingController/saveInfo",
          type : "POST",
          async : true,
          dataType : "json",
          data : $.param({
            buildingType : systemCode,
            buildingHight : systemName,
            buildingColor : systemColor,
            buildingMarks:that.dataForSon.message,
            customProperties:customProperties,
            ciId:this.checkedValue1

          }),
          success : function(result) {
            if (result == false) {
              alert("添加失败")
            }

            if (result == true){
              alert("添加成功")
              that.closeItself();
            }

          },

        });
      },
      closeItself(){
        this.$parent.shutdownSubmitwindows()
      },
      getAllCrt(){
        var that = this
        jQuery.ajax({
          url : this.dataForSon.baseUrl + "iotechserver/crtInfo/getAllCrt",
          type : "get",
          beforeSend: function(request) {
              request.setRequestHeader("token",  dataStorage.getBaseData("iotechtj_token"));
             
          },
          async : true,
          dataType : "json",
          data : $.param({

          }),
          success : function(result) {
            that.tableData = []
            result.data.list.forEach(element => {
              that.tableData.push({
                date: element.createTime,
                name: element.id,
                address: element.name
              })

            });


          },

        });
      },
      shutdownSubmitwindows(){
        var that = this
        that.submitWindows = false

        that.tableData = null
      },
      startSubmitwindows(){

        var that = this
        that.getAllCrt()

        that.submitWindows = []
      }
    }
  }

</script>
<style lang='scss' scoped>
  .submitWindowsViewClass{
    z-index: 9999;
    width: auto;
    min-width: 22rem;
    padding: .75rem 1.25rem;
    margin-left: 1.25rem;
    margin-top: 10rem;
    position: fixed;
    top: 1rem;
    color: #000;
    // display: none;


  }
  .displayNone{
    display: none;
  }
  .displayBlock{
    display: block;
  }
</style>