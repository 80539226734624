<!--  -->
<template>
  <div class="container-fluid panel-custom" style="background:#fff ;width:1000px;height:400px;overflow-x:auto">

    <div class="row col-md-12 col-sm-12 col-xs-12">
      <div class="row col-sm-12  form-group">
        <label class="col-sm-10 control-label"><span
                style="color: red">*</span>设备id：</label>
        <label class="col-sm-12 control-label" id="buildType"></label>
        <div class="col-sm-10">
          <input class="form-control" name="deviceId" id="deviceId" required  v-model="deviceId"/>
        </div>
      </div>


    </div>

    <div class="row col-md-12 col-sm-12 col-xs-12"
         style="text-align: center;">
      <button type="button" class="btn btn-primary btn-sm confirm" @click="save()">
        <span class="glyphicon glyphicon-ok" ></span>保存
      </button>
      <button type="button" class="btn btn-primary btn-sm cancel" @click="closeItself()">
        <span class="glyphicon glyphicon-remove" ></span>取消
      </button>


    </div>
  </div>
</template>

<script>
  import jQuery from 'jquery'
  import $ from 'jquery'
  import dataStorage from '@/../utils/dataStorage.js'

  export default {
    data () {
      return {
        baseURL:"",
        //属性键值对匹配
        sxInt : 0,
        jsonStr :"",
        this_ciId : "0",
        deviceId : "",
        submitWindows:false,
      };
    },

    props: {
      dataForSon: {
        baseUrl:"",
        message:"",
        ciId:"",
      }
    },


    computed: {},

    mounted() {
    },

    methods: {

      save() {
        var that= this

        if ($("#deviceId").val() == null || $("#deviceId").val() == "") {
          alert("请填写设备编号")
          return null;
        }


        jQuery.ajax({
          url : this.dataForSon.baseUrl + "Zz3dmapBuildingController/saveDeviceLoLa",
          type : "POST",
          async : true,
          dataType : "json",
          data : $.param({
            deviceId:that.deviceId,
            buildingMarks:that.dataForSon.message,

          }),
          success : function(result) {
            if (result == false) {
              alert("添加失败")
            }

            if (result == true){
              that.deviceId = ""
              alert("添加成功")
              that.closeItself();
            }

          },

        });
      },
      closeItself(){
        this.$parent.shutdownSubmitwindowsDeviceInfo()
      },
      shutdownSubmitwindows(){
        var that = this
        that.submitWindows = false

        that.tableData = null
      },
      startSubmitwindows(){

        var that = this
        that.getAllCrt()

        that.submitWindows = []
      }
    }
  }

</script>
<style lang='scss' scoped>
  .submitWindowsViewClass{
    z-index: 9999;
    width: auto;
    min-width: 22rem;
    padding: .75rem 1.25rem;
    margin-left: 1.25rem;
    margin-top: 10rem;
    position: fixed;
    top: 1rem;
    color: #000;
    // display: none;


  }
  .displayNone{
    display: none;
  }
  .displayBlock{
    display: block;
  }
</style>